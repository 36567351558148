<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Consumer
      <router-link slot="right" to="/" class="button">
        Dashboard
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <card-component>
        <em>
          <p class="pb-2">Send an ArtifactRequestMessage to a Connector to retrieve an artifact. And if necessary, send an ContractRequestMessage and start the contract negotiation sequence.</p>
          <p class="pb-2">The Request Contract form will accept all of the rules inside the Contract Offer during the contract negotiation sequence.</p>
          <p>The agreed upon contracts can be viewed at the <router-link to="/pef/pap">Policy Administration Point</router-link>.</p>
        </em>
      </card-component>
      <card-component title="Request artifact" icon="download" headerColor="primary">
        <form @submit.prevent="requestArtifact">
          <b-field label="Artifact ID" horizontal>
            <b-input
              v-model="retrieve.artifact"
              placeholder="Artifact ID (URI)"
              validation-message="Please enter a URI (https:// or urn:)"
              type="url"
              required
            />
          </b-field>
          <b-field label="Connector ID" horizontal>
            <b-input
              v-model="retrieve.connectorId"
              placeholder="IDS ID (URI)"
              validation-message="Please enter a URI (https:// or urn:)"
              type="url"
              required
            />
          </b-field>
          <b-field label="Agent ID" horizontal>
            <b-input
              v-model="retrieve.agentId"
              placeholder="Agent IDS ID (URI) (optional)"
              validation-message="Please enter a URI (https:// or urn:)"
              type="url"
            />
          </b-field>
          <b-field label="Access URL" horizontal>
            <b-input
              v-model="retrieve.accessUrl"
              placeholder="Connector Access URL (URI)"
              validation-message="Please enter a URI (https:// or urn:)"
              type="url"
              required
            />
          </b-field>
          <b-field label="Transfer Contract" horizontal>
            <b-input
              v-model="retrieve.transferContract"
              placeholder="Transfer Contract ID (URI) (optional, but might be required by the other connector)"
              validation-message="Please enter a URI (https:// or urn:)"
              type="url"
            />
          </b-field>
          <hr />
          <b-field horizontal>
            <b-field grouped>
              <div class="control">
                <b-button native-type="submit" type="is-primary"
                  >Request artifact</b-button
                >
              </div>
              <div class="control">
                <b-button type="is-primary is-outlined" @click="()=>{retrieve = {artifact: null, connectorId: null, agentId: null, accessUrl: null, transferContract: null }}"
                  >Reset</b-button
                >
              </div>
            </b-field>
          </b-field>
        </form>
      </card-component>
      <div ref="contractRequest">
      <contract-request-card :connectorId="connectorId" :agentId="agentId" :accessUrl="accessUrl" :offer="offer" @requestedContract="(agreementId) => {retrieve.transferContract = agreementId}" />
      </div>
    </section>
  </div>
</template>

<script>
// GET /artifact (artifact, connectorId, agentId?, accessUrl, transferContract?)
// POST /contractRequest (connectorId, agentId?, contractOffer, accessUrl)

import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
import ContractRequestCard from '@/components/ContractRequestCard'
// import axios from 'axios'
import { mapState } from 'vuex'
// const api = axios.create({
//   // baseURL: '/api/',
//   baseURL: typeof webpackHotUpdate !== 'undefined' ? 'http://localhost:8080/api/' : '/api/',
//   timeout: 5000
// })

export default {
  name: 'ArtifactConsumer',
  components: {
    HeroBar,
    CardComponent,
    TitleBar,
    ContractRequestCard
  },
  props: {
    artifact: {
      type: String,
      required: false
    },
    connectorId: {
      type: String,
      required: false
    },
    agentId: {
      type: String,
      required: false
    },
    accessUrl: {
      type: String,
      required: false
    },
    offer: {
      type: String,
      required: false
    },
    filename: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      retrieve: {
        artifact: localStorage.getItem('artifactId'),
        connectorId: localStorage.getItem('connectorId'),
        agentId: localStorage.getItem('agentId'),
        accessUrl: localStorage.getItem('accessUrl'),
        transferContract: localStorage.getItem('transferContract')
      },
      form: {
        name: null,
        email: null,
        phone: null,
        department: null,
        subject: null,
        question: null
      }
    }
  },
  created () {
    if (this.artifact) {
      this.retrieve.artifact = this.artifact
      localStorage.setItem('artifactId', this.artifact || '')
    }
    if (this.connectorId) {
      this.retrieve.connectorId = this.connectorId
      localStorage.setItem('connectorId', this.connectorId || '')
    }
    if (this.agentId) {
      this.retrieve.agentId = this.agentId
      localStorage.setItem('agentId', this.agentId || '')
    }
    if (this.accessUrl) {
      this.retrieve.accessUrl = this.accessUrl
      localStorage.setItem('accessUrl', this.accessUrl || '')
    }
  },
  mounted () {
    if (this.offer) {
      setTimeout(() => {
        var element = this.$refs.contractRequest
        var top = element.offsetTop - 60
        console.log(element.offsetTop)

        window.scrollTo(0, top)
      }, 100
      )
    }
  },
  computed: {
    titleStack () {
      return ['Artifact Handling', 'Consumer']
    },
    ...mapState(['api'])
  },
  methods: {
    async requestArtifact () {
      try {
        localStorage.setItem('artifact', this.retrieve.artifactId || '')
        localStorage.setItem('connectorId', this.retrieve.connectorId || '')
        localStorage.setItem('agentId', this.retrieve.agentId || '')
        localStorage.setItem('accessUrl', this.retrieve.accessUrl || '')
        localStorage.setItem('transferContract', this.retrieve.transferContract || '')
        const response = await this.api.get('/artifacts/consumer/artifact', {
          params: this.retrieve,
          transformResponse: (res) => res,
          responseType: 'blob'
        })

        const url = window.URL.createObjectURL(new Blob([response?.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.filename || `${this.retrieve.artifact}.bak`)
        document.body.appendChild(link)
        link.click()
      } catch (e) {
        var description
        if (e.response) {
          description = `HTTP Error: ${e.response.status}<br />${e.response.data}`
        } else if (e.request) {
          description = `HTTP Request Error:
${JSON.stringify(e.request)}`
        } else {
          description = `Unexpected Error: ${e.message}`
        }
        this.$buefy.snackbar.open({
          message: `Error: ${description}`,
          type: 'is-danger',
          position: 'is-bottom',
          queue: false,
          duration: 20000
        })
      }
    }
  }
}
</script>
