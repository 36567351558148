<template>
  <card-component title="Request Contract" icon="file-document-edit" headerColor="info">
      <form @submit.prevent="requestContract">
          <b-field label="Connector ID" horizontal>
          <b-input
              v-model="contract.connectorId"
              placeholder="IDS ID (URI)"
              validation-message="Please enter a URI (https:// or urn:)"
              type="url"
              required
          />
          </b-field>
          <b-field label="Agent ID" horizontal>
          <b-input
              v-model="contract.agentId"
              placeholder="Agent IDS ID (URI) (optional)"
              validation-message="Please enter a URI (https:// or urn:)"
              type="url"
          />
          </b-field>
          <b-field label="Access URL" horizontal>
          <b-input
              v-model="contract.accessUrl"
              placeholder="Connector Access URL (URI)"
              validation-message="Please enter a URI (https:// or urn:)"
              type="url"
              required
          />
          </b-field>
          <b-field label="Contract Offer" horizontal>
          <b-input
              v-model="contract.offer"
              type="textarea"
              :placeholder="contractPlaceholder"
              rows="10"
              required
          />
          </b-field>
          <hr />
          <b-field horizontal>
          <b-field grouped>
              <div class="control">
              <b-button native-type="submit" type="is-primary"
                  >Request Contract</b-button
              >
              </div>
              <div class="control">
              <b-button type="is-primary is-outlined" @click="()=>{contract = {connectorId: null, agentId: null, accessUrl: null, offer: null }}"
                  >Reset</b-button
              >
              </div>
          </b-field>
          </b-field>
      </form>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import { mapState } from 'vuex'
export default {
  name: 'ContractRequestCard',
  components: {
    CardComponent
  },
  props: {
    connectorId: {
      type: String,
      required: false
    },
    agentId: {
      type: String,
      required: false
    },
    accessUrl: {
      type: String,
      required: false
    },
    offer: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      contractPlaceholder: `{
  "@type": "ids:ContractOffer",
  "@id": "https://w3id.org/idsa/autogen/contractOffer/00000000-0000-0000-0000-000000000000",
  ...
}`,
      contract: {
        connectorId: localStorage.getItem('connectorId'),
        agentId: localStorage.getItem('agentId'),
        accessUrl: localStorage.getItem('accessUrl'),
        offer: localStorage.getItem('offer')
      }
    }
  },
  created () {
    if (this.connectorId) {
      this.contract.connectorId = this.connectorId
      localStorage.setItem('connectorId', this.connectorId || '')
    }
    if (this.agentId) {
      this.contract.agentId = this.agentId
      localStorage.setItem('agentId', this.agentId || '')
    }
    if (this.accessUrl) {
      this.contract.accessUrl = this.accessUrl
      localStorage.setItem('accessUrl', this.accessUrl || '')
    }
    if (this.offer) {
      this.contract.offer = this.offer
      localStorage.setItem('offer', this.offer || '')
    }
  },
  computed: {
    ...mapState(['api'])
  },
  methods: {
    async requestContract () {
      const formData = new FormData()
      formData.append('connectorId', this.contract.connectorId)
      formData.append('contractOffer', this.contract.offer)
      formData.append('accessUrl', this.contract.accessUrl)
      if (this.contract.agentId) {
        formData.append('agentId', this.contract.agentId)
      }

      try {
        const contractAgreement = await this.api.post('/artifacts/consumer/contractRequest', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        this.contract = {
          connectorId: null,
          agentId: null,
          accessUrl: null,
          offer: null
        }
        this.$buefy.toast.open({
          message: 'Successfully negotiated a contract agreement',
          type: 'is-success',
          position: 'is-bottom'
        })
        localStorage.setItem('transferContract', contractAgreement.data['@id'] || '')
        this.$emit('requestedContract', contractAgreement.data['@id'])
      } catch (e) {
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom'
        })
        console.log(e)
      }
    }
  }
}
</script>
